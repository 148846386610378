// @ts-nocheck we are going to come back and check each in-use file
// --- Event Tracking functions ---
/*
    Listen for click events on any element with the attribute data-patient-app-click.
    recordEvent() appends an img tag to the body with src path set to our RecordEventView
    which returns a transparent pixel. When the image loads, or we encounter an error,
    remove img from the page. We add a time param to the url path to ensure the image is not
    cached by the browser.

    Also listen for mouseenter and focus events on any element with the attribute data-patient-app-hover.
    This allows us to capture engagements on elements like tooltips which don't require an actual
    click. Though it is not technically a click, we will still track this as a PATIENT_APP_CLICK
    in our events table since there is no meaningful difference between click and hover when it
    comes to tracking engagement.

    getEventDetails() checks data-patient-app-click-data-id to determine whether we have additional
    data we want to track along with the click and returns stringified JSON if details are present,
    or null if JSON is invalid.

    NOTE: This file is named 'patient_app_stats.js' to avoid ad-block software which blocks files with names
    related to tracking and analytics.
*/
$(function () {
    $("body").on("click", "[data-patient-app-click]", function () {
        const event_target = this.dataset.patientAppClick;
        const event_details_id = this.dataset.patientAppClickDetailsId;
        let event_details = null;
        if (event_details_id) {
            event_details = getEventDetails(event_details_id);
        }
        const event_type = "PATIENT_APP_CLICK";
        // check that function is loaded before calling to avoid alerts
        if (typeof cedar === "undefined" || typeof cedar.deprecatedRecordEvent !== "function") {
            return;
        }
        cedar.deprecatedRecordEvent({
            event_type: event_type,
            event_target: event_target,
            event_details: event_details,
        });
    });

    $("body").on("mouseenter", "[data-patient-app-hover]", function () {
        const event_target = this.dataset.patientAppHover;
        const event_details_id = this.dataset.patientAppHoverDetailsId;
        const event_type = "PATIENT_APP_CLICK";
        // check that function is loaded before calling to avoid alerts
        if (typeof cedar === "undefined" || typeof cedar.deprecatedRecordEvent !== "function") {
            return;
        }
        cedar.deprecatedRecordEvent({
            event_type: event_type,
            event_target: event_target,
            event_details: null,
        });
    });

    $("body").on("focus", "[data-patient-app-hover]", function () {
        const event_target = this.dataset.patientAppHover;
        const event_details_id = this.dataset.patientAppHoverDetailsId;
        const event_type = "PATIENT_APP_CLICK";
        // check that function is loaded before calling to avoid alerts
        if (typeof cedar === "undefined" || typeof cedar.deprecatedRecordEvent !== "function") {
            return;
        }
        cedar.deprecatedRecordEvent({
            event_type: event_type,
            event_target: event_target,
            event_details: null,
        });
    });

    function getEventDetails(details_id) {
        const json_data = $("#" + details_id).text();
        let details = null;
        if (json_data) {
            try {
                const valid_json = JSON.stringify(JSON.parse(json_data));
                details = valid_json;
            } catch (e) {
                console.error("Error parsing JSON: ", e);
                details = null;
            }
        }
        return details;
    }
});

export {};
